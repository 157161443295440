<template>
  <layout-full>
    <router-view :key="$route.fullPath" />
  </layout-full>
</template>

<script>
import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue'
import { scanBarcode } from '@/mixins'

export default {
  components: {
    LayoutFull,
  },

  mixins: [scanBarcode],

  mounted() {
    this.onScanAttachDefault()
  },
}
</script>
